import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Resumecontent from "./ResumeContent";
import pdf from "../../Assets/Manjot_Resume.pdf";
import { AiOutlineDownload } from "react-icons/ai";

function Resume() {
  useEffect(() => {}, []);

  return (
    <Container fluid className="resume-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
        <Row className="resume">
          <Col md={6} className="resume-left">
            <h3 className="resume-title">Experience</h3>
            <Resumecontent
              title="Full Stack Developer [TATA DIGITAL]"
              date="October 2019 - Present"
              content={[
                "Working in building frontend for mobile and web application",
                "Building PWA stack in implementing web application.",
                "Working in integrating microservices from backend servers.",
                "Building microservices to get data from cosmo db In Node js and Springboot • Helping teams to build their webapps on linus servers using Azure Devops."
              ]}
            />
            <Resumecontent
              title="Full Stack Developer [RELIANCE INDUSTRIES]"
              date="August 2018 - October 2019"
              content={[
                "Worked for automation of spark scripts for hql queries",
                "Build User friendly interface of D E framework using Angular (frontend) and spring boot for Microservices along with MySQL Database.",
                "Working in integrating microservices from backend servers.",
                "Build the Zoom Data Dashboard for status of running and failed jobs in D E framework."
              ]}
            />
            <h3 className="resume-title">Extracurricular Activities</h3>
            <Resumecontent
              title="Web Developer Intern [Mettl]"
              content={[
                "Build the Application using the HTML , CSS which contained the inbuilt tests for users where they could login the tests offline and the result would be saved locally"
              ]}
            />
          </Col>
          <Col md={6} className="resume-right">
            <h3 className="resume-title">Education</h3>
            <Resumecontent
              title="BE, COMPUTER ENGINEERING, THAPAR UNIVERSITY"
              date="2014 - 2018"
              content={[`CGPA: 7.4`]}
            />
            <Resumecontent
              title="HIGHER SECONDARY, BUDHA DAL PUBLIC SCHOOL"
              date="2013 - 2014"
              content={["Precentage: 88%"]}
            />
            <Resumecontent
              title="SECONDARY, BUDHA DAL PUBLIC SCHOOL "
              date="2011 - 2012"
              content={["Precentage: 95%"]}
            />
            <h3 className="resume-title">Ranks and Achivements</h3>
            <Resumecontent
              title=""
              content={[
                "Top Performer in Bhangra Nationals",
                "Participant in hackathons at university levels"
              ]}
            />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", position: "relative" }}>
          <Button variant="primary" href={pdf} target="_blank">
            <AiOutlineDownload />
            &nbsp;Download CV
          </Button>
        </Row>
      </Container>
    </Container>
  );
}

export default Resume;
